<template>
  <div class="site-wrap">
    <Header
      :mode="2"
      :searchKey="searchKey"
      :cityCode="cityCode"
      @search="doSearch"
    ></Header>
    <div class="main-wrap">
      <MapListTemplate>
        <div
          class="left-panel no-scrollbar"
          :class="{ 'no-pager': isHidePager }"
          slot="leftPanel"
          v-loading="isLoading"
        >
          <div class="left-navs">
            <ul>
              <li @click="hideCollectList">
                <FilterBtn
                  :data="filterMenusData"
                  :filterData="curFilterData"
                  :isSelectClose="true"
                  @selectFilter="selectFilter"
                >
                  <Icon custom="icon-filter"></Icon>
                  高级筛选
                </FilterBtn>
              </li>
              <li
                :class="{ active: isShowCollection }"
                @click="changeCollectShow"
              >
                <Icon custom="icon-favorite"></Icon>
                我的收藏
              </li>
            </ul>
          </div>
          <div
            class="left-list-content"
            :class="{ 'g-login-padding': !isLogin() }"
          >
            <ul>
              <li
                v-for="(item, index) in showList"
                :key="'show_' + index"
                :class="{ cur: curBrandId === item.code }"
                @click="handleSelectBrand(item)"
                :ref="`brandListItem${index}`"
              >
                <div
                  class="pic cur-pointer"
                  @click.stop="gotoMallDetail(item.code)"
                >
                  <img :src="item.logo || normalPic" alt="" />
                </div>
                <div class="list-cont">
                  <div>
                    <div class="title">{{ item.name }}</div>
                    <span
                      class="collect"
                      v-if="!item.isCollect"
                      @click.stop="addCollect(item.code)"
                      >收藏</span
                    >
                    <span
                      class="collect no-collect"
                      @click.stop="delCollect(item.code)"
                      v-else
                      >取消收藏</span
                    >
                  </div>
                  <div class="list-item">
                    地址: <a>{{ item.addr }}</a>
                  </div>
                  <div class="list-item">
                    开业时间: <a>{{ item.startTime }}</a>
                    <span
                      class="link hover-show"
                      @click.stop="gotoMallDetail(item.code,item.cityName)"
                      >查看详情</span
                    >
                  </div>
                </div>
                <div class="cur-box"></div>
                <img
                  class="triangle-icon"
                  src="../../assets/images/icon/triangle.png"
                  alt=""
                />
              </li>
              <template v-if="!isLogin()">
                <li class="visitor-login">
                  <div
                    role="button"
                    class="visitor-login-btn"
                    @click="gotoLogin"
                  >
                    登录查看全部信息<img
                      class="visitor-login-icon"
                      :src="require('@/assets/images/double-arror.png')"
                      width="14"
                      height="14"
                    />
                  </div>
                </li>
              </template>
            </ul>
            <div class="no-data" v-if="!isLoading && showList.length === 0">
              <no-data class="center-middle-box"></no-data>
            </div>
            <!-- <Spin fix v-if="isLoading"></Spin> -->
          </div>
          <PagerLittle
            class="left-list-pager"
            :total="total"
            :page="page"
            :pageSize="pageSize"
            @on-change="handlePageChange"
          ></PagerLittle>
        </div>
        <MapPanel
          ref="mapPanel"
          :isSelectCity="true"
          :ignoreFitChina="true"
          @loaded="handleMapLoaded"
          @click="handleMapClick"
          @selectCity="handleSelectCity"
        ></MapPanel>
      </MapListTemplate>
    </div>
  </div>
</template>

<script>
import FilterBtn from "@/components/filter/FilterBtn.vue";
import MapPanel from "@/components/MapPanel.vue";
import PagerLittle from "@/components/PagerLittle";
import { mallFilters } from "js/mallData.js";
import { SearchData, GetDetailBrowsingRecord } from "@/service";
import normalPic from "@/assets/images/normal_stores.png";
import mallMixin from "./mall.js";
import collectMixin from "@/mixins/collect.js";
import InfoWindowBrand from "../../components/InfoWindowBrand.vue";
import Vue from "vue";
import { openNewWindow, login, isLogin } from "js/utils.js";

export default {
  mixins: [mallMixin, collectMixin],
  metaInfo: {
    title: "查商场",
    meta: [
      {
        name: "keywords",
        content:
          "千里目,全国商场,商场数据,商场排名,商场分布,城市商场数量,商场开业时间,商场面积,经营年限,商场类型,商场入驻品牌,对比分析",
      },
      {
        name: "description",
        content:
          "千里目覆盖全国各地商场数据，具备精准搜索、通过地图快速定位、对比分析等功能，支持查看全国商场位置分布、不同城市商场数量、商场开业时间、商场面积、经营年限、商场类型、商场入驻品牌等详细数据。",
      },
    ],
  },
  data() {
    return {
      isLoading: true,
      mapPanel: null,
      isLoadedMap: false,
      curFilterData: {},
      searchKey: "",
      cityCode: "",
      page: 1,
      pageSize: 20,
      total: 0,
      mallList: [],
      normalPic: normalPic,
      collectList: [], // 收藏列表
      isShowCollection: false, // 显示收藏列表

      curBrandId: "",
    };
  },
  computed: {
    sort() {
      return this.$store.getters.getSortId("mall");
    },
    filterMenusData() {
      return {
        title: "筛选",
        filters: mallFilters,
      };
    },
    showList() {
      if (this.isShowCollection) {
        return this.collectList;
      }
      return this.mallList;
    },
    isHidePager() {
      return this.isShowCollection || this.total < this.pageSize;
    },
  },
  methods: {
    async initData() {
      for (let i = 0, n = mallFilters.length; i < n; i++) {
        let item = mallFilters[i];
        this.curFilterData[item.key] = [];
        this.$set(this.curFilterData, item.key, this.curFilterData[item.key]);
      }
      await this.mapPanel.waitLoaded();
      this.mapPanel.changeCityName("北京市");
      this.handleSelectCity({
        adcode: this.cityCode,
      });
    },
    isLogin,
    gotoLogin: login,
    async initTable() {
      this.isLoading = true;
      let filters = [];
      for (let key in this.curFilterData) {
        let val = this.curFilterData[key];
        if (!val || val.length === 0) {
          continue;
        }
        filters.push({
          field: key,
          condition: val,
        });
      }
      if (this.cityCode) {
        filters.push({
          field: "cityCode",
          condition: [this.cityCode],
        });
      }
      let params = {
        searchSort: this.sort,
        word: this.searchKey,
        page: this.page || 1,
        pageSize: this.pageSize || 10,
        filterCondition: filters,
        orderCondition: {
          field: "floorAndStore",
          condition: "desc",
        },
        lat: "",
        lon: "",
      };
      let res = await SearchData(params);
      if (res && res.code === 200) {
        let list = res.data.items || [];
        // 查询收藏状态
        list = await this.checkListCollectStatus(list);
        this.mallList = list;
        this.total = res.data.pages.total;
      }
      this.isLoading = false;

      this.showPoints(this.mallList);
    },
    doSearch(searchKey) {
      this.searchKey = searchKey;
      this.page = 1;
      this.initTable();
    },
    selectFilter(value) {
      let obj = this.curFilterData || {};
      for (let key in value) {
        obj[key] = value[key];
      }
      this.curFilterData = Object.assign({}, this.curFilterData, obj);
      this.page = 1;
      this.initTable();
    },
    //翻页
    handlePageChange(page) {
      this.page = page;
      this.initTable();
    },
    handleMapLoaded() {
      this.isLoadedMap = true;
    },
    handleMapClick(feature) {
      if (!feature) {
        return;
      }
      this.curBrandId = feature.properties.code || "";
      let divHeight = this.$refs.brandListItem0[0].offsetHeight;
      let topDistance = 0;
      for (let i in this.showList) {
        if (this.showList[i].code === feature.properties.code) {
          topDistance = Number(i) * divHeight;
        }
      }
      this.scrollToBottom(topDistance);
      this.openWindowInfo(feature);
    },
    //列表滚动到某个位置
    scrollToBottom(topDistance) {
      this.$nextTick(() => {
        let list = this.$el.querySelector(".left-list-content");
        list.scrollTop = topDistance; //滚动到距离元素顶部 40px
      });
    },
    openWindowInfo(feature) {
      let properties = feature.properties;
      let center = feature.geometry.coordinates;
      let InfoWindow = Vue.extend(InfoWindowBrand);
      let instance = new InfoWindow({
        propsData: {
          title: properties["name"],
          list: [
            `地址: ${properties["addr"]}`,
            `开业时间: ${properties["startTime"]}`,
          ],
          btnText: "查看详情",
        },
      });
      instance.$on("doSubmit", () => {
        let code = properties.code;
        this.gotoMallDetail(code,properties.cityName);
      });
      instance.$mount();
      let content = instance.$el;
      let that = this;
      that.mapPanel.openInfoWindow(center, content, {
        offset: [0, -23],
        autoMove: false,
        close() {
          that.curBrandId = "";
        },
      });
    },

    // 选择左侧列表
    handleSelectBrand(item) {
      if (this.curBrandId === item.code) {
        return;
      }
      this.curBrandId = item.code;
      this.openLeftItem(item);
    },
    openLeftItem(item) {
      let feature = this.getFeatureByItem(item);
      this.openWindowInfo(feature);
      this.mapPanel.fitPointBounds(feature, { immediately: true });
    },
    // 选择城市
    handleSelectCity(city) {
      this.cityCode = city.adcode;
      this.page = 1;
      this.initTable();
      this.mapPanel.fitBoundsDistrict(city.adcode);
    },
    showPoints(list) {
      let features = [];
      for (let i = 0, n = list.length; i < n; i++) {
        let item = list[i];
        let feature = this.getFeatureByItem(item);
        features.push(feature);
      }
      let options = {
        icon: "/images/marker_blue2.png",
        iconSize: [22, 33],
        anchor: "bottom-center",
      };
      this.mapPanel.setMarkers("markers", features, options);
    },
    getFeatureByItem(item) {
      let geom = item.geom;
      if (typeof geom === "string") {
        geom = JSON.parse(geom);
      }
      let feature = {
        type: "Feature",
        geometry: geom,
        properties: item,
      };
      return feature;
    },
    async gotoMallDetail(code,cityName) {
      const res = await GetDetailBrowsingRecord({
        module: "mallDetail",
        detailID: code,
      });
      if (res && res.code == 200 && res.data == "success") {
        openNewWindow("/mallDetail", {
          id: code,
          cityName:cityName
        });
      }
    },
  },
  mounted() {
    this.mapPanel = this.$refs.mapPanel;
    this.cityCode = localStorage.getItem("cityCode");
    let searchKey = this.$route.query.key;
    if (searchKey) {
      this.searchKey = searchKey;
    }
    this.$nextTick(() => {
      this.initData();
    });
  },
  components: {
    MapPanel,
    FilterBtn,
    PagerLittle,
  },
};
</script>

<style></style>
