export const mallFilters = [
  {
    label: "商业面积",
    name: "商业面积",
    key: "area",
    submenu: [
      {
        label: "不限",
        value: "0",
      },
      {
        label: "5万m²以下",
        value: "1",
      },
      {
        label: "5～10万m²",
        value: "2",
      },
      {
        label: "10～20万m²",
        value: "3",
      },
      {
        label: "20万m²以上",
        value: "4",
      },
    ],
  },
  {
    label: "经营年限",
    name: "经营年限",
    key: "openYear",
    submenu: [
      {
        label: "不限",
        value: "0",
      },
      {
        label: "1年以内",
        value: "1",
      },
      {
        label: "1～3年",
        value: "2",
      },
      {
        label: "3～5年",
        value: "3",
      },
      {
        label: "5～10年",
        value: "4",
      },
      {
        label: "10～20年",
        value: "5",
      },
    ],
  },
  {
    label: "商场类型",
    name: "商场类型",
    key: "businessType",
    submenu: [
      {
        label: "不限",
        value: "0",
      },
      {
        label: "都市型",
        value: "1",
      },
      {
        label: "区域型",
        value: "2",
      },
      {
        label: "社区型",
        value: "3",
      },
      {
        label: "奥特莱斯",
        value: "4",
      },
    ],
  },
  {
    label: "商场品牌",
    name: "商场品牌",
    key: "brand",
    submenu: [
      {
        label: "不限",
        value: "0",
      },
      {
        label: "吾悦广场",
        value: "1",
      },
      {
        label: "印象城",
        value: "2",
      },
      {
        label: "爱琴海",
        value: "3",
      },
      {
        label: "凯德广场",
        value: "4",
      },
      {
        label: "印象汇",
        value: "5",
      },

      {
        label: "凯德MALL",
        value: "6",
      },
      {
        label: "恒隆广场",
        value: "7",
      },
      {
        label: "太古里",
        value: "8",
      },
      {
        label: "世茂广场",
        value: "9",
      },
      {
        label: "印象里",
        value: "10",
      },
      {
        label: "太古汇",
        value: "11",
      },
    ],
  },
];
